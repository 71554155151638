<template>
  <div class="wrapper">
    <div>
      <div class="b-title">获奖情况</div>
      <el-form inline label-width="80px">
        <el-form-item label="学年">
          <el-select v-model="query.year" placeholder="请选择学年" clearable>
            <el-option
              v-for="item in yearOpt"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学期">
          <el-select v-model="query.semester" placeholder="请选择学期" clearable>
            <el-option
              v-for="item in semesterOpt"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="奖项名称">
          <el-input v-model="query.projectName" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="searchHandle('reward')">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="rewardTable"
        border
        :header-cell-style="{ background: '#eff3ff', color: '#5799f4' }"
        style="width: 100%">
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="60">
        </el-table-column>
        <el-table-column
          prop="projectName"
          align="center"
          label="项目">
        </el-table-column>
        <el-table-column
          prop="type"
          align="center"
          width="120"
          label="级别">
        </el-table-column>
        <el-table-column
          prop="organizer"
          align="center"
          label="主办单位">
        </el-table-column>
        <el-table-column
          prop="time"
          align="center"
          width="120"
          label="获奖时间">
        </el-table-column>
        <el-table-column
          prop="rank"
          align="center"
          width="120"
          label="名次奖级">
        </el-table-column>
        <el-table-column
          prop="score"
          align="center"
          width="60"
          label="加分">
          <strong slot-scope="score" style="color:#217bff">{{score.row.score}}</strong>
        </el-table-column>
        <el-table-column
          prop="yearText"
          align="center"
          width="130"
          label="学年">
        </el-table-column>
        <el-table-column
          prop="semester"
          align="center"
          width="100"
          label="学期">
          <span slot-scope="score">{{score.row.semester == '1'?'第一学期':'第二学期'}}</span>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        layout="prev, pager, next"
        :page-size="query.pageSize"
        :current-page="query.pageNo"
        @current-change="rewardTableChange"
        :total="rewardTableTotal">
      </el-pagination>
    </div>
    <div>
      <div class="b-title">违规违纪</div>
      <el-form inline label-width="80px">
        <el-form-item label="学年">
          <el-select v-model="violateQuery.year" placeholder="请选择学年" clearable>
            <el-option
              v-for="item in yearOpt"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学期">
          <el-select v-model="violateQuery.semester" placeholder="请选择学期" clearable>
            <el-option
              v-for="item in semesterOpt"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="违规项目">
          <el-input v-model="violateQuery.projectName" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="searchHandle('violate')">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="violateTable"
        border
        :header-cell-style="{ background: '#eff3ff', color: '#5799f4' }"
        style="width: 100%">
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="60">
        </el-table-column>
        <el-table-column
          prop="projectName"
          align="center"
          label="违规违纪项目">
        </el-table-column>
        <el-table-column
          prop="type"
          align="center"
          label="违规违纪类别">
        </el-table-column>
        <el-table-column
          prop="time"
          align="center"
          width="120"
          label="违规违纪时间">
        </el-table-column>
        <el-table-column
          prop="score"
          align="center"
          width="60"
          label="扣分">
          <strong slot-scope="score" style="color:#217bff">{{score.row.score}}</strong>
        </el-table-column>
        <el-table-column
          prop="yearText"
          align="center"
          width="130"
          label="学年">
        </el-table-column>
        <el-table-column
          prop="semester"
          align="center"
          width="100"
          label="学期">
          <span slot-scope="score">{{score.row.semester == '1'?'第一学期':'第二学期'}}</span>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        layout="prev, pager, next"
        :page-size="violateQuery.pageSize"
        :current-page="violateQuery.pageNo"
        @current-change="violateTableChange"
        :total="violateTableTotal">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: {
        year:'',
        semester:'',
        projectName:'',
        pageNo:1,
        pageSize:10,
      },
      yearOpt:[],
      semesterOpt:[
        {
          key:1,
          value:'第一学期'
        },
        {
          key:2,
          value:'第二学期'
        }
      ],
      levelOpt:[],
      rewardTable:[],
      rewardTableTotal:0,
      violateQuery:{
        year:'',
        semester:'',
        projectName:'',
        pageNo:1,
        pageSize:10,
      },
      violateTable:[],
      violateTableTotal:0,
      typeOpt:[],
    }
  },
  mounted(){
    this.getYear()
    this.getRewardTable()
    this.getViolateTable()
    },
  methods: {
    getYear(){
      this.$get('myCommon/school/yearAfterByLoginStu').then(res=>{
        this.yearOpt = res.result;
      })
    },
    getRewardTable(){
      this.$get('evas/awardRecords/statics/getAwardStatics',this.query).then(res=>{
        // console.log(res.result.result,'======')
        this.rewardTable = res.result.result.records;
        this.rewardTableTotal = res.result.result.total;
        this.getLevel()
      })
    },
    rewardTableChange(val){
      this.query.pageNo = val;
      this.getRewardTable()
    },
    getLevel(){
      this.$get('sys/dict/getDictItem/record_award_level').then(res=>{
        // console.log(res,'=====erer')
        this.levelOpt = res.result
      })
    },
    //
    getViolateTable(){
      this.$get('evas/punishmentRecords/statics/getPunishmentRecordsStatics',this.violateQuery).then(res=>{
        this.violateTable = res.result.result.records;
        this.violateTableTotal = res.result.result.total;
      })
    },
    violateTableChange(val){
      this.violateQuery.pageNo = val;
      this.getViolateTable()
    },
    //
    searchHandle(type){
      if(type == "reward"){
        this.query.pageNo = 1;
        this.getRewardTable()
      }else if(type == "violate"){
        this.violateQuery.pageNo = 1;
        this.getViolateTable()
      }
    }
  },

}
</script>

<style lang="scss" scoped>
.wrapper{
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  min-height: 800px;
}
.b-title{
  font-size: 18px;
  color: #000;
  padding-left: 15px;
  position: relative;
  font-weight: 600;
  margin-bottom: 15px;
  &::after{
    display: block;
    content: "";
    width: 6px;
    background: #217bff;
    border-radius: 30px;
    position: absolute;
    left: 0;
    top: 3px;
    bottom: 3px;
  }
}
.opt-wrap{
  display: flex;
  .item{
    display: flex;
    margin-bottom: 20px;
    margin-right: 20px;
  }
}
.pagination{
  text-align: right;
  padding: 10px 0;
  margin-bottom: 20px;
}
</style>
